<template>
  <div class="offers">
    <div class="row no-offers"
      v-show="!allOffers.length">
      <div class="col">
        <p>No currently available offers</p>
      </div>
    </div>
    <div class="row"
      v-show="allOffers.length">
      <div class="title">
        <h4>All Offers</h4>
      </div>
      <div
        class="col-sm"
        v-for="offer in allOffers"
        :key="offer.identifier"
        :id="offer.identifier">
        <div
          class="offer-item noselect text-center"
          @click="select(offer)">
          <div class="name">
            {{offer.name}}
          </div>
          <div class="price">
              £{{offer.total_price / 100 / offer.num_lessons}} per lesson
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../../../common/utils/event-bus';

export default {
  name: 'AllOffers',
  props: {
    allOffers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    select(offer) {
      const plan = offer;
      plan.type = 'offer';
      plan.allBenefits = plan.benefits;
      EventBus.$emit('selectedPlan', plan);
    },
  },
};
</script>

<style scoped>
.offers .no-offers p {
  margin: 0;
  text-align: center;
}

.offers > .row {
  margin-top: 50px;
}

.offers > .row:first-child,
.offers > .row:nth-child(2) {
  margin-top: 1em;
}

.offers > .row:last-child {
  margin-bottom: 50px;
}

.offer-item {
  padding: 25px 10px;
  cursor: pointer;
  box-shadow: 0px 0.0375em 0.125em 0px rgba(0, 0, 0, 0.35);
}

.offer-item .name {
  font-size: 1.15em;
  font-weight: bold;
}

.title {
  text-align: center;
}

@media screen and (max-width: 595px) {
  .title {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .offers > .row > .col-sm {
    padding-top: 15px;
  }
}
</style>
