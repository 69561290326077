<template>
  <div class="payments page container">
    <h2>All Plans</h2>
    <all-plans></all-plans>
    <b-modal
      id="modal-payment-details"
      :title="selectedPlan.name"
      ref="paymentsModal"
      hide-footer
      size="lg"
      @show="onPaymentsModalShow"
      @shown="onModalShown"
      @hidden="onModalHide">
      <div class="content">
        <div class="benefits">
          <h4>{{selectedPlan.type | capitalize}} Overview</h4>
          <ul>
            <li
              v-for="benefit in selectedPlan.allBenefits"
              :key="benefit">
              {{ benefit }}
            </li>
          </ul>
        </div>
        <hr v-show="selectedPlan.total_price > 0">
        <div class="payment-details" v-show="selectedPlan.total_price > 0">
          <h4>Your Payment Details</h4>
          <p>Total to pay: <b>£{{ selectedPlan.total_price / 100 }}</b></p>
          <form id="payment-form" class="align-items-center">
            <div ref="card" class="form-control"></div>
            <div v-show="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>
          </form>
        </div>
        <footer class="modal-footer">
          <subscribe-button
            v-if="selectedPlan.type == 'subscription'"
            :stripe="stripe"
            :card="card"
            :plan="selectedPlan"
            :lockButton="lockPayButton"
            :onSuccess="onSubscriptionSuccess"
            :onError="onSubscriptionError"></subscribe-button>
          <buy-pack-button
            v-else-if="selectedPlan.type == 'pack'"
            :stripe="stripe"
            :card="card"
            :plan="selectedPlan"
            :lockButton="lockPayButton"
            :onSuccess="onBuyingPackSuccess"
            :onError="onBuyingPackError"></buy-pack-button>
          <buy-offer-button
            v-else
            :stripe="stripe"
            :card="card"
            :plan="selectedPlan"
            :lockButton="lockPayButton"
            :onSuccess="onBuyingOfferSuccess"
            :onError="onBuyingOfferError"></buy-offer-button>
        </footer>
      </div>
    </b-modal>
    <b-modal
      id="modal-payment-confirmation"
      title="Payment Success"
      hide-footer
      size="md">
      Congrats! 🎉
      <br>
      <br>
      You bought already <i>{{ selectedPlan.name }}</i> {{ selectedPlan.type }}.
      Now be patient, please, this <b>might take a while before</b> you can
      <b>schedule</b> your <b>lessons</b>. As soon as you receive our email
      with the receipt everything will be ready to start
      learning Spanish.
      <br>
      <br>
      Welcome to MySpanishNow family!
      <footer class="modal-footer payment-confirmation">
        <button class="button special small"
                @click="okPaymentConfirmation">Ok, I understand</button>
      </footer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import AllPlans from '../../components/student/profile/payments/AllPlans.vue';
import SubscribeButton from '../../components/student/profile/payments/utils/SubscribeButton.vue';
import BuyPackButton from '../../components/student/profile/payments/utils/BuyPackButton.vue';
import BuyOfferButton from '../../components/student/profile/payments/utils/BuyOfferButton.vue';
import EventBus from '../../components/common/utils/event-bus';
import MiscApi from '../../api/Misc';
import types from '../../store/types';

export default {
  name: 'Payments',
  components: {
    AllPlans,
    SubscribeButton,
    BuyPackButton,
    BuyOfferButton,
  },
  data() {
    return {
      selectedPlan: {},
      error: null,
      stripe: {},
      card: {},
      lockPayButton: true,
    };
  },
  methods: {
    ...mapActions({
      spinner: types.actions.SPINNER,
    }),
    okPaymentConfirmation() {
      const currentRoute = this.$router.history.current;
      if (currentRoute.query && currentRoute.query.redirect) {
        this.$router.push({ name: currentRoute.query.redirect });
        return;
      }
      this.$router.back();
    },
    onBuyingOfferSuccess(plan) {
      this.spinner(false);
      window.fbq('track', 'Purchase', {
        content_name: plan.identifier,
        currency: 'gbp',
        value: plan.total_price / 100,
      });
      this.$bvModal.show('modal-payment-confirmation');
    },
    onBuyingOfferError(error) {
      this.spinner(false);
      this.error = this.parseError(error, 'Unable to buy offer. Please try again');
    },
    onBuyingPackSuccess(plan) {
      this.spinner(false);
      window.fbq('track', 'Purchase', {
        content_name: plan.identifier,
        currency: 'gbp',
        value: plan.total_price / 100,
      });
      this.$bvModal.show('modal-payment-confirmation');
    },
    onBuyingPackError(error) {
      this.spinner(false);
      this.error = this.parseError(error, 'Unable to buy pack. Please try again');
    },
    onSubscriptionSuccess(plan) {
      this.spinner(false);
      window.fbq('track', 'Subscribe', {
        currency: 'gbp',
        value: plan.total_price / 100,
        predicted_ltv: 11,
      });
      this.$bvModal.show('modal-payment-confirmation');
    },
    onSubscriptionError(error) {
      this.spinner(false);
      this.error = this.parseError(error, 'Unable to subscribe to plan. Please try again');
    },
    initStripe() {
      MiscApi
        .getStripeKey()
        .then((response) => {
          this.stripe = window.Stripe(response.data.pub);
          this.card = this.stripe.elements().create('card');
          this.card.mount(this.$refs.card);
          this.card.on('change', (event) => {
            if (event.error) {
              this.error = event.error.message;
            }
            this.lockPayButton = event.error;
          });
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Payment service currently unavailable. Please try later');
        });
    },
    onPaymentsModalShow() {
      this.error = null;
      this.initStripe();
    },
    onPaymentProcess() {
      this.error = null;
    },
    onModalShown() {
      const modalEl = document.querySelector('#modal-payment-details');
      disableBodyScroll(modalEl);
    },
    onModalHide() {
      clearAllBodyScrollLocks();
    },
  },
  created() {
    EventBus.$on('selectedPlan', (plan) => {
      this.selectedPlan = plan;
      if (this.$refs.paymentsModal === undefined) {
        return;
      }
      this.$refs.paymentsModal.show();
    });
    EventBus.$on('buyingSubscription', this.onPaymentProcess);
    EventBus.$on('buyingPack', this.onPaymentProcess);
  },
};
</script>

<style scoped>
  #payment-form {
    width: 100%;
    padding: 0;
  }

  footer {
    padding-left: 0;
    padding-right: 0;
  }

  footer.modal-footer.payment-confirmation {
    margin-top: 1rem;
  }
</style>

<style>
  .alert.alert-danger {
    margin-top: 10px;
  }
</style>
