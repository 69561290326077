<template>
  <input :disabled="lockButton"
         class="button special"
         type="submit"
         value="Subscribe"
         v-on:click.prevent="subscribe"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PlansApi from '../../../../../api/Plans';
import types from '../../../../../store/types';

export default {
  name: 'SubscribeButton',
  props: {
    stripe: {
      type: Object,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    lockButton: {
      type: Boolean,
      required: true,
    },
    onSuccess: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
  methods: {
    ...mapActions({
      spinner: types.actions.SPINNER,
    }),
    subscribe() {
      // Required due to multiple handles being called
      this.spinner(true);
      const user = this.currentUser;
      return this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            email: user.email,
            name: `${user.profile.first_name} ${user.profile.last_name}`,
          },
        })
        .then((result) => {
          if (result.error) {
            throw Error(result.error.message);
          }

          const plan = { ...this.plan };
          return PlansApi
            .buySubscription(plan.identifier, result.paymentMethod.id)
            .then((response) => ({
              plan,
              paymentMethodId: result.paymentMethod.id,
              subscription: response.data,
            }))
            .then(this.handlePaymentThatRequiresCustomerAction)
            .then(this.handleRequiresPaymentMethod)
            .then(this.onSubscriptionComplete);
        })
        .catch(this.onError);
    },
    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      plan,
      paymentMethodId,
      isRetry,
    }) {
      if (subscription && subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, plan, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

      if (paymentIntent.status === 'requires_action'
        || (isRetry === true && paymentIntent.status === 'requires_payment_method')) {
        return this.stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((response) => {
            const result = response;
            if (result.error) {
              // start code flow to handle updating the payment details
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              throw Error(result.error.message);
            }

            if (result.paymentIntent.status !== 'succeeded') {
              throw Error('Unable to fully process payment');
            }

            return {
              plan,
              subscription,
              invoice,
              paymentMethodId,
            };
          });
      }

      // No customer action needed
      return { subscription, plan, paymentMethodId };
    },
    handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      plan,
    }) {
      if (subscription.status !== 'active'
        && subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        // subscription is active, no customer actions required.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem('latestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status);
        throw Error('Your card was declined.');
      }

      return { subscription, plan, paymentMethodId };
    },
    onSubscriptionComplete({ plan }) {
      // Payment was successful. Provision access to your service.
      // Remove invoice from localstorage because payment is now complete.
      this.clearCache();
      this.onSuccess(plan);
      // Call your backend to grant access to your service based on
      // the product your customer subscribed to.
      // Get the product by using result.subscription.price.product
    },
    clearCache() {
      localStorage.removeItem('latestInvoiceId');
      localStorage.removeItem('latestInvoicePaymentIntentStatus');
    },
  },
};
</script>
