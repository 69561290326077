<template>
  <input
    type="submit"
    class="button special"
    :value="plan.identifier === 'freelesson' ? 'Use Free Lesson' : 'Buy Offer'"
    :disabled="plan.identifier === 'freelesson' ? false : lockButton"
    v-on:click.prevent="buyOffer"/>
</template>

<script>
import { mapActions } from 'vuex';
import PlansApi from '../../../../../api/Plans';
import types from '../../../../../store/types';

export default {
  name: 'BuyOfferButton',
  props: {
    stripe: {
      type: Object,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    lockButton: {
      type: Boolean,
      required: true,
    },
    onSuccess: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      spinner: types.actions.SPINNER,
    }),
    buyOffer() {
      if (this.plan.total_price === 0) {
        this.onSuccess(this.plan);
        return;
      }

      // Required due to multiple handles being called
      this.spinner(true);
      PlansApi
        .buyOffer(this.plan.identifier)
        .then((response) => {
          const clientSecret = response.data.client_secret;
          const stripeData = {
            payment_method: {
              card: this.card,
            },
          };
          this.stripe.confirmCardPayment(clientSecret, stripeData)
            .then((result) => {
              if (result.error) {
                this.onError(result.error.message);
                return;
              }

              this.onSuccess(this.plan);
            })
            .catch(this.onError);
        })
        .catch(this.onError);
    },
  },
};
</script>
