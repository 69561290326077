<template>
  <div class="packs">
    <div class="row">
      <div class="title">
        <h4>All Packs</h4>
      </div>
      <div
        class="col-sm"
        v-for="pack in allPacks"
        :key="pack.identifier"
        :id="pack.identifier">
        <div
          class="pack-item noselect text-center"
          @click="select(pack)">
          <div class="name">
            {{pack.name}}
          </div>
          <div class="price">
              £{{pack.total_price / 100 / pack.num_lessons}} per lesson
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../../../common/utils/event-bus';

export default {
  name: 'AllPacks',
  props: {
    allPacks: {
      type: Array,
      required: true,
    },
    benefits: {
      type: Object,
      required: true,
    },
  },
  methods: {
    select(pack) {
      const plan = pack;
      plan.type = 'pack';
      if (this.benefits[plan.identifier]) {
        plan.allBenefits = [...this.benefits[plan.identifier]];
      } else {
        let benefits = [...this.benefits.common];
        if (this.benefits.packs) {
          benefits = benefits.concat(this.benefits.packs);
        }
        plan.allBenefits = benefits;
      }
      EventBus.$emit('selectedPlan', pack);
    },
  },
};
</script>

<style scoped>
.packs > .row {
  margin-top: 50px;
}

.packs > .row:first-child {
  margin-top: 1em;
}

.packs > .row:last-child {
  margin-bottom: 50px;
}

.pack-item {
  padding: 25px 10px;
  cursor: pointer;
  box-shadow: 0px 0.0375em 0.125em 0px rgba(0, 0, 0, 0.35);
}

.pack-item .name {
  font-size: 1.15em;
  font-weight: bold;
}

.title {
  text-align: center;
}

@media screen and (max-width: 595px) {
  .title {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .packs > .row > .col-sm {
    padding-top: 15px;
  }
}
</style>
