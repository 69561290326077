<template>
  <div class="subscriptions">
    <div class="row description"
      v-show="description && description.subscriptions">
      <div class="col">
        <p v-html="description.subscriptions"></p>
      </div>
    </div>
    <div
      class="row"
      v-for="(allSubscriptionsPerType, index) in allSubscriptions"
      :key="index">
      <div class="title">
        <h4>{{index}}</h4>
      </div>
      <div
        class="col-sm"
        v-for="subscription in allSubscriptionsPerType"
        :key="subscription.identifier"
        :id="subscription.identifier">
        <div
          class="subscription-item text-center"
          :class="{'most-popular': subscription.most_popular,
            'recommended': subscription.recommended}"
          @click="select(subscription)">
          <div class="name">
            {{subscription.num_lessons}} {{subscription.num_lessons > 1 ? 'Lessons' : 'Lesson'}}
          </div>
          <div class="price">
            £{{subscription.total_price / 100}} per {{subscription.repeat}}
          </div>
          <div
            class="most-popular-text"
            v-show="subscription.most_popular">
            <label>Most Popular</label>
          </div>
          <div
            class="recommended-text"
            v-show="subscription.recommended">
            <label>Recommended</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../../../common/utils/event-bus';

export default {
  name: 'AllSubscriptions',
  props: {
    allSubscriptions: {
      type: Object,
      required: true,
    },
    benefits: {
      type: Object,
      required: true,
    },
    description: {
      type: Object,
      required: true,
    },
  },
  methods: {
    select(subscription) {
      const plan = subscription;
      plan.type = 'subscription';
      let benefits = [...this.benefits.common];
      if (this.benefits.subscriptions) {
        benefits = benefits.concat(this.benefits.subscriptions);
      }
      plan.allBenefits = benefits;
      EventBus.$emit('selectedPlan', subscription);
    },
  },
};
</script>

<style scoped>
.description p {
  margin: 0;
  text-align: right;
  font-style: italic;
}

.subscriptions > .row {
  margin-top: 50px;
}

.subscriptions > .row:last-child {
  margin-bottom: 50px;
}

.subscriptions > .row:first-child,
.subscriptions > .row:nth-child(2) {
  margin-top: 1em;
}

.subscription-item {
  padding: 25px 10px;
  cursor: pointer;
  box-shadow: 0px 0.0375em 0.125em 0px rgba(0, 0, 0, 0.35);
  position: relative;
}

.subscription-item .name {
  font-size: 1.15em;
  font-weight: bold;
}

.subscription-item .name > i {
  font-size: 0.7em;
}

.subscription-item.most-popular,
.subscription-item.recommended {
  box-shadow: none;
  border: solid 1px #f32853;
}

.subscription-item.most-popular .most-popular-text,
.subscription-item.recommended .recommended-text {
  position: absolute;
  top: -21px;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #f32853;
  padding: 4px 5px;
  border-radius: 5px 5px 0 0;
}

.subscription-item.most-popular .most-popular-text label,
.subscription-item.recommended .recommended-text label {
  margin-bottom: 0;
  color: #fff;
  font-style: normal;
  line-height: 13px;
  font-weight: normal;
}

.title {
  text-align: center;
}

@media screen and (max-width: 595px) {
  .title {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .subscriptions > .row > .col-sm {
    padding-top: 15px;
  }
}
</style>
