<template>
  <div class="all-plans">
    <div class="row text-center">
      <div class="col">
        <b-form-group>
          <b-form-radio-group
            v-model="selectedPlanType"
            @change="selectPlan"
            :options="plansOptions"
            buttons
            name="radios-btn-default"
            class="radio-group special"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <all-offers
      v-if="selectedPlanType == 'offers'"
      :allOffers="allPlans.offers"></all-offers>
    <all-packs
      v-else-if="selectedPlanType == 'packs'"
      :allPacks="allPlans.packs"
      :benefits="benefits"></all-packs>
    <all-subscriptions
      v-else
      :allSubscriptions="allPlans.subscriptions"
      :benefits="benefits"
      :description="description"></all-subscriptions>
  </div>
</template>

<script>
import PlansApi from '../../../../api/Plans';
import AllSubscriptions from './plans_type/AllSubscriptions.vue';
import AllPacks from './plans_type/AllPacks.vue';
import AllOffers from './plans_type/AllOffers.vue';

export default {
  name: 'AllPlans',
  components: {
    AllSubscriptions,
    AllPacks,
    AllOffers,
  },
  data() {
    return {
      benefits: {},
      allPlans: {
        subscriptions: {},
        packs: [],
        offers: [],
      },
      selectedPlanType: 'subscriptions',
      description: {},
      selectedPlan: null,
      error: null,
      plansOptions: [
        { text: 'Subscriptions', value: 'subscriptions' },
        { text: 'Packs', value: 'packs' },
        { text: 'Offers', value: 'offers' },
      ],
    };
  },
  methods: {
    selectPlan(planType) {
      this.$router.replace({
        path: `/profile/plans/${planType}`,
        query: {
          redirect: this.$router.history.current.query.redirect,
        },
      });
    },
    getAllPlans() {
      PlansApi
        .all()
        .then((response) => {
          this.allPlans = response.data.data;
          this.benefits = response.data.benefits;
          this.description = response.data.description;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to retrieve all packs');
        });
    },
    getCurrentPlanType() {
      let planType = this.$route.path.split('/');
      planType = planType[planType.length - 1];
      for (let i = 0; i < this.plansOptions.length; i += 1) {
        const option = this.plansOptions[i];
        if (option.value === planType) { return planType; }
      }
      return 'subscriptions';
    },
  },
  created() {
    this.selectedPlanType = this.getCurrentPlanType();
  },
  mounted() {
    this.getAllPlans();
  },
};
</script>
